<template>
<div>
  <button v-if="!switchShowingSvar" v-on:click="showConfirm()">Sammenlign svar: Åpne</button>
  <button v-if="switchShowingSvar" v-on:click="showConfirm()">Sammenlign svar: Lukk</button>
  <div><button onclick="window.open('https://stastudent.no/valgavis/','_blank')">Valgavis</button>
  <button onclick="window.open('https://valg.usit.no/valg/login.do','_blank')">Stem her</button></div>

  <!-- These buttons change text depending on which state the page is in. Open/close comparison -->
  <h3 v-if="fakultet == 1">Fakultet for Humaniora og Pedagogikk</h3>
  <h3 v-if="fakultet == 2">Fakultet for Teknologi og Realfag</h3>
  <h3 v-if="fakultet == 3">Handelshøyskolen</h3>
  <h3 v-if="fakultet == 4">Avdeling for Lærerutdanningen</h3>
  <h3 v-if="fakultet == 5">Fakultet for Kunstfag</h3>
  <h3 v-if="fakultet == 6">Fakultet for Samfunnnsvitenskap</h3>
  <h3 v-if="fakultet == 7">Fakultet for Helse og Idrett</h3>


  <div class="list" v-for="kandidat in kandidaterSorted" :key="kandidat.x.ID">
    <!-- For each candidate make own content. The lists get sorted by points and faculty in another function-->
      <hr> <!-- Estetic purposes. -->
      <p>{{kandidat.x.Name}}</p>
      <img v-if="kandidat.x.Picture !== null" :src="require('./../../assets/pictures/' + kandidat.x.Picture)" :alt="kandidat.x.Picture" style="height: 40vh; width: auto;">
      <!-- If there exist a picture, the picture will display. Make sure to place it in the right folder and name accordingly.-->

      <p v-if="kandidat.y.Poeng > 0">Poeng: {{kandidat.y.Poeng}}</p> <!-- Display points if total points is over 0. -->
      <p v-else-if="kandidat.y.Poeng <= 0 && kandidat.x.Q3 !== null || kandidat.x.Q7 !== null">Poeng: 0</p>  <!-- Display 0 if points are a negative number. -->
      <p v-else><i>Ingen svar.</i></p>

      <div v-if="kandidat.x.StillerTil === 1"> <!-- Display where the candidate runs (SP or faculty board). Differentiate between faculties and departments-->
        <p v-if="kandidat.x.Fac === 4">
          Stiller til både Studentparlementet og Avdelingsstyret
        </p>
        <p v-else>
          Stiller til både Studentparlamentet og Fakultetsstyret
        </p>
      </div>
    <div v-else-if="kandidat.x.StillerTil === 2"><p>Stiller kun til Studentparlamentet</p></div>
      <div v-else-if="kandidat.x.StillerTil === 3">
        <p v-if="kandidat.x.Fac === 4">
          Stiller kun til Avdelingsstyret
        </p>
        <p v-else>
          Stiller kun til Fakultetsstyret
        </p>
      </div>
      <p v-else></p> <!-- Display empty if its an error here for some reason. Could be cut. -->

      <div v-if="switchShowingSvar && kandidat.x.Q1 !== null && kandidat.x.Q5 !== null" >
        <pre class="toNormalText">{{getText(kandidat)}}</pre>
      </div>
      <!-- Display comparisons. Only is the bool is true. If the cands ans to q1 and q5 doesn't exist, then display no answer.-->
      <div v-else-if="switchShowingSvar">
        <pre class="toNormalText">Ingen svar.</pre>
      </div>
  </div>
  <hr class="skillelinje"> <!-- Estetic purposes. -->
  <button onclick="window.open('https://valg.usit.no/valg/login.do','_blank')">Stem her</button>
  <button v-if="!switchShowingSvar" v-on:click="showConfirm()">Sammenlign: Åpne</button>
  <button v-if="switchShowingSvar" v-on:click="showConfirm()">Sammenlign svar: Lukk</button>
  <!-- These buttons change text depending on which state the page is in. Open/close comparison. Same as at the beginning.
   We want to have buttons at the start and end of this page.-->
</div>
</template>

<script>

import {points, questions, sharedVars} from "../../sharedVars"
import {cand} from "../../candidates"
import * as path from "path";

export default {
  name: "Result",
  data:function (){
    return{
      kandidaterSorted: [], //Declare array for holding sorted candidates.
      switchShowingSvar: false, //Boolean for display comparison
    }
  },
  computed:{
    kandidater: function (){return cand.candidates}, //Import candidates from sharedVars.
    fakultet: function (){return sharedVars.fakultet}, //Get Faculty of the one taking the quiz (Answer to question 0).
    points: function (){return points}, //Get the array of points, which we will soon link to each candidate.
  },
  methods: {
    path() {
      return path
    },
    result() { //This function puts the candidates together with their points, and then sorts them.
      let temp = [];
      for (let i in this.kandidater) {
        if (this.kandidater[i].Fac === this.fakultet) {
          const x = this.kandidater[i];
          const y = {Poeng: this.points[i]}
          temp.push({x, y});
        }
      }
      temp.sort(function (a, b) {
        if(a.x.Q5 === null){
          return 1;
        }
        else if (a.y.Poeng > b.y.Poeng)
          return -1;
        else if (a.y.Poeng < b.y.Poeng)
          return 1;
        else
          return 0;
      });

      this.kandidaterSorted = Array.from(temp);

      //console.log(this.kandidaterSorted[0], this.kandidaterSorted[1]); //For testing.
    },
    showConfirm() { //This switches the comparison boolean when the appropriate button are clicked.
      this.switchShowingSvar = !this.switchShowingSvar

    },
    getText(kandidat){ //Put the comparison text in own function not to clutter the template too much.
    let temp =
        "Hybridløsning er bedre enn utelukkende fysisk forelesning." + "\n" +
        "Ditt svar: "+ this.numToAns(questions.get(1))
        + "\nKandidatens svar: " + this.numToAns(kandidat.x.Q1) + "\n\n" +
        "UiA bør anerkjenne mer enn to kjønn." + "\n" +
        "Ditt svar: "+ this.numToAns(questions.get(2)) + "\n"
        + "Kandidatens svar: " + this.numToAns(kandidat.x.Q2) + "\n\n" +
        "Kurs/modul i korrekt kildehenvisning bør være obligatorisk. " + "\n" +
        "Ditt svar: "+ this.numToAns(questions.get(3)) + "\n"
        + "Kandidatens svar: " + this.numToAns(kandidat.x.Q3) + "\n\n" +
        "Bærekraft og bærekraftsmålene må integreres mer i pensum." + "\n" +
        "Ditt svar: "+ this.numToAns(questions.get(4)) + "\n"
        + "Kandidatens svar: " + this.numToAns(kandidat.x.Q4) + "\n\n" +
        "Skoleeksamen burde være normen." + "\n" +
        "Ditt svar: "+ this.numToAns(questions.get(5)) + "\n"
        + "Kandidatens svar: " + this.numToAns(kandidat.x.Q5) + "\n\n" +
        "UiA har et behov for større fokus på mangfold." + "\n" +
        "Ditt svar: "+ this.numToAns(questions.get(6)) + "\n"
        + "Kandidatens svar: " + this.numToAns(kandidat.x.Q6) + "\n\n" +
        "Alle undervisere bør ha mer fokus på studentaktiv undervisning." + "\n" +
        "Ditt svar: "+ this.numToAns(questions.get(7)) + "\n"
        + "Kandidatens svar: " + this.numToAns(kandidat.x.Q7) + "\n\n" +
        "Alle studentene på UiA bør få tilbud om praksis i utdanningsløpet sitt." + "\n" +
        "Ditt svar: "+ this.numToAns(questions.get(8)) + "\n"
        + "Kandidatens svar: " + this.numToAns(kandidat.x.Q8) + "\n\n" +
        "UiA skal jobbe for at flere drar på utveksling." + "\n" +
        "Ditt svar: "+ this.numToAns(questions.get(9)) + "\n"
        + "Kandidatens svar: " + this.numToAns(kandidat.x.Q9) + "\n\n" +
        "Man skal i større grad benytte bestått/ikke bestått fremfor karakterskala på endelig eksamen." + "\n" +
        "Ditt svar: "+ this.numToAns(questions.get(10)) + "\n"
        + "Kandidatens svar: " + this.numToAns(kandidat.x.Q10) + "\n\n"
    return temp;

    },
    numToAns(num){ //Translates numbers to answers for comparison purposes. Easier readability for end user.
      switch (num){
        case 1||"1":
          return "Helt Enig"
        case 2||"2":
          return "Delvis Enig"
        case 3||"3":
          return "Nøytal"
        case 4||"4":
          return "Delvis Uenig"
        case 5||"5":
          return "Helt Uenig"
        case 6||"6":
          return "Vet Ikke"

      }
      return "Ingen svar";
    }
  },
  beforeMount() {
    this.result(); //Run the result function before the page loads.
  }
}
</script>

<style scoped>

</style>